<template>
    <div>
        <div style="text-align: center">
            <h3>Tambah Paket</h3>
        </div>
        <br />
        <form v-on:submit.prevent="addNewPackage">
            <label for="">Nama Paket</label>
            <input type="text" class="form-control" v-model="form.name" placeholder="Nama Paket" required />
            <br>
            <label for="">Jumlah Lisensi</label>
            <input type="number" class="form-control" v-model="form.lisensi" required />
            <div v-if="form.lisensi > 1">
                <br>
                <label>Allow Paket Agensi*</label>
                <multiselect v-model="form.whitelist" tag-placeholder="Pilih Whitelist Paket"
                        placeholder="Cari Paket" label="name" track-by="id" :options="packages"
                        :multiple="true"></multiselect>
            </div>
            <br>
            <label for="">Kuota Pesan</label>
            <input type="number" class="form-control" v-model="form.message_quota" required />
            <br>
			<label for="">Kuota Webook Media (GB) </label>
            <input type="number" class="form-control" v-model="form.webhook_media_quota" required />
            <br>
            <label for="">Maksimal Device</label>
            <input type="number" class="form-control" v-model="form.max_device" required />
            <br>
            <label for="">Maksimal CS</label>
            <input type="number" class="form-control" v-model="form.max_cs" required />
            <br>
            <label for="">Maksimal Campaign</label>
            <input type="number" class="form-control" v-model="form.max_campaign" required />
            <br>
            <label for="">Maksimal Order Online</label>
            <input type="number" class="form-control" v-model="form.max_order_online" required />
            <br>
            <label for="">Maksimal Product Link Rotator</label>
            <input type="number" class="form-control" v-model="form.max_link_rotator" required />
            <br>
            <label for="">Maksimal Chat Widget</label>
            <input type="number" class="form-control" v-model="form.max_chat_widget" required />
            <br>
            <div class="form-check">
                <input class="form-check-input" v-model="form.is_addon" type="checkbox" value="1"
                    id="flexCheckDefault2">
                <label class="form-check-label" for="flexCheckDefault2">
                    Add On
                </label>
            </div>
            <div v-if="!form.is_addon">
                <br>
                <label for="">Durasi (Hari)</label>
                <input type="number" class="form-control" v-model="form.duration" required />
            </div>
            <br>
                <label for="">Harga (Rupiah)</label>
                <input type="number" class="form-control" v-model="form.price" required />
            <br>
            <div class="form-check">
                <input class="form-check-input" v-model="form.hide" type="checkbox" value="1"
                    id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    Sembunyikan
                </label>
            </div>
            <br>
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    // import moment from 'moment'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    name: null,
                    lisensi: 1,
                    message_quota: null,
                    max_device: null,
                    max_cs: null,
                    max_campaign: null,
                    max_order_online: null,
                    max_link_rotator: null,
                    max_chat_widget: null,
                    is_addon: false,
                    duration: null,
                    price: null,
                    hide: false,
                    whitelist: [],
                    webhook_media_quota: null,
                },
                packages: [],
                null_value: null
            };
        },
        mounted() {
            this.getPackage()
        },
        methods: {
            toggleModal(param) {
                this.$modal.show(param);
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async addNewPackage() {
                try {
                    let req = {
                        name: this.form.name,
                        lisensi: parseInt(this.form.lisensi ?? 0),
                        message_quota: parseInt(this.form.message_quota ?? 0),
                        max_device: parseInt(this.form.max_device ?? 0),
                        max_cs: parseInt(this.form.max_cs ?? 0),
                        max_campaign: parseInt(this.form.max_campaign ?? 0),
                        max_order_online: parseInt(this.form.max_order_online ?? 0),
                        max_link_rotator: parseInt(this.form.max_link_rotator ?? 0),
                        max_chat_widget: parseInt(this.form.max_chat_widget ?? 0),
                        is_addon: this.form.is_addon ? 1 : 0,
                        duration: parseInt(this.form.duration ?? 0),
                        price: parseInt(this.form.price ?? 0),
                        hide: this.form.hide ? 1 : 0,
                        webhook_media_quota:parseInt(this.form.webhook_media_quota)*1000000 ?? 0
                    }

                    let whitelist = []
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        whitelist.push({
                            id: this.form.whitelist[i].id
                        })
                    }

                    req.whitelist = whitelist

                    let response = await this.$store.dispatch('admin_package_create', req)
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "new_paket")
                    this.$root.$emit("refresh_table", true)
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>

</style>
