<template>
    <div>
        <form v-on:submit.prevent="sendSingle" enctype="multipart/form-data">
            <label for="">Pengirim</label>
            <multiselect v-model="form.sender" tag-placeholder="Pilih Device Pengirim"
                placeholder="Pilih Device Pengirim" label="name" :custom-label="customLabel" track-by="id"
                :options="device" :multiple="true">
            </multiselect>
            <br>
            <label for="">User Level</label>
            <select v-model="form.user_level" class="form-control">
                <option value="all" selected>All</option>
                <option value="user">User</option>
                <option value="user-agency">User Agency</option>
                <option value="agency">Agency</option>
            </select>
            <br>
            <label for="">Paket</label>
            <select v-model="form.paket" class="form-control">
                <option value="all" selected>All</option>
                <option value="paid">Paid</option>
                <option value="trial">Trial</option>
            </select>
            <br>
            <label for="">Status</label>
            <select v-model="form.status" class="form-control">
                <option value="all" selected>All</option>
                <option value="active">Aktif</option>
                <option value="inactive">Tidak Aktif</option>
            </select>
            <br>
            <label for="">Saldo Minimal</label>
            <input type="number" v-model="form.balance" class="form-control" required>
            <br>
            <label for="">Jenis Pesan</label>
            <select v-model="form.message_type" class="form-control">
                <option value="text">Text / Media</option>
                <!-- <option value="button">Button</option>
                <option value="list" disabled>List</option> -->
            </select>
            <br>
            <div v-if="form.message_type == 'text'">
                <label for="">Text</label>
                <CustomTextArea v-model="form.text"></CustomTextArea>
                <br>
                <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                <input ref="file" type="file" @change="onFileChange" class="form-control">
            </div>
            <div v-else-if="form.message_type == 'button'" class="card thick-border">
                <div class="card-body">
                    <h5 style="text-align: center;">BUTTON RESPONSE</h5>
                    <label for="">Teks Button</label>
                    <CustomTextArea v-model="form.text"></CustomTextArea>
                    <br>
                    <label> Gambar (jpg, png) </label>
                    <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png" class="form-control">
                    <br>
                    <button type="button" class="btn btn-primary btn-sm" v-on:click="addButton"
                        style="margin:5px">Tambah Button</button>
                    <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteButton"
                        style="margin:5px">Hapus Button</button>

                    <br>
                    <div v-for="btn in button" v-bind:key="btn.id">
                        <br>
                        <div class="card thick-border">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">Tipe Button</label>
                                        <select v-model="btn.type" class="form-control">
                                            <option value="quickReplyButton">Quick Reply (Tulisan Biasa)</option>
                                            <option value="urlButton">URL</option>
                                            <option value="callButton">Call Nomor Telepon</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <label for="">Display Text (Tulisan Pada Button)</label>
                                            <input type="text" class="form-control" v-model="btn.displayText" required>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div v-if="btn.type == 'urlButton'">
                                    <label for="">URL</label>
                                    <input type="text" class="form-control" v-model="btn.url" placeholder="https://"
                                        required>
                                </div>
                                <div v-else-if="btn.type == 'callButton'">
                                    <label for="">Nomor Telepon</label>
                                    <input type="text" class="form-control" v-model="btn.phoneNumber"
                                        placeholder="08xxxxxxxx" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <label for="">Footer</label>
                    <input type="text" v-model="form.footer" placeholder="Footer" class="form-control">
                </div>
                <div style="margin: 5px">
                    <i>* Gambar hanya bisa tampil jika semua button berjenis Quick Reply</i>
                    <br>
                    <i>* Android Menampilkan Maksimal 3 Button</i>
                    <br>
                    <i>* Whatsapp Web Menampilkan Maksimal 5 Button</i>
                    <br>
                    <i>* IOS Unlimited Button</i>
                </div>
            </div>
            <br>
            <input v-model="form.send_now" class="checkbox" type="checkbox" id="sendnow">
            <label for="sendnow">
                <span for="sendnow" class="checkbox-text">&nbsp;Kirim pesan sekarang</span>
            </label>
            <br>
            <br>
            <div v-if="!form.send_now">
                <label for="">Tanggal & Jam</label>
                <v-date-picker class="inline-block h-full" v-model="form.date" mode="dateTime" is24hr>
                    <template v-slot="{ inputValue, togglePopover }">
                        <div>
                            <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                        </div>
                    </template>
                </v-date-picker>
                <br>
                <br>
            </div>
            <label> Delay Interval (Detik) </label>
            <div class="row">
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_start" placeholder="Dari" class="form-control">
                </div>
                <div class="col-md-2">
                    <center>
                        <b style="text-align: center;">SAMPAI</b>
                    </center>
                </div>
                <div class="col-md-5">
                    <input type="number" v-model="form.delay_end" placeholder="Sampai" class="form-control">
                </div>
            </div>
            <br>
            <i>- Anda bisa menggunakan dynamic string untuk generate random string, contoh : (halo|hai|pagi|siang)
            </i>
            <br>
            <i>- Variabel tersedia {username}, {email}, {phone}, {paket}, {balance}, {expired}</i>
            <br>
            <br>
            <button class="btn btn-success btn-block">Kirim Pesan</button>
        </form>
        <modal name="send_progress" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
            <div style="margin: 20px;text-align:center">
                <div class="spinner-border spinner" role="status"></div>
                <br>
                <h2>{{ progress }}%</h2>
                <br>
                <h3>SEDANG MEMPERSIAPKAN PESAN</h3>
                <p>Jangan Tutup Halaman Ini</p>
            </div>
        </modal>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        props: ['device'],
        components: {
            CustomTextArea,
            Multiselect
        },
        data() {
            return {
                form: {
                    sender: [],
                    user_level: "all",
                    paket: "all",
                    status: "all",
                    balance: 0,
                    text: "",
                    file: "",
                    date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    footer: "",
                    message_type: "text",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60
                },
                page: {
                    one: true,
                    two: false,
                    three: false
                },
                multiselect: {
                    value: [],
                    options: []
                },
                progress: 0,
                contact: [],
                group: [],
                button: [],
                button_index: 0,
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            customLabel({
                name,
                status
            }) {
                return `${name} (${status})`
            },
            resetInput() {
                this.$refs.file.value = null;
                this.form = {
                    sender: "",
                    user_level: "all",
                    paket: "all",
                    status: "all",
                    balance: 0,
                    text: "",
                    file: "",
                    date: "",
                    footer: "",
                    message_type: "text",
                    send_now: true,
                    delay_start: 30,
                    delay_end: 60
                }
                this.button = []
                this.button_index = 0
                this.multiselect.value = []
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.form.file = selectedFile;
            },
            addButton() {
                this.button.push({
                    type: "quickReplyButton",
                    displayText: "",
                    url: "",
                    phoneNumber: "",
                    id: "id-" + (this.button_index + 1)
                })

                this.button_index += 1

                this.$toast.success("Tambah Button Sukses");
            },
            deleteButton() {
                if (this.button_index > 0) {
                    this.button.splice(-1)
                    this.button_index -= 1

                    this.$toast.success("Hapus Button Sukses");
                }
            },
            renderMessage(variable, text) {
                text = text.replaceAll("{username}", variable['username'])
                text = text.replaceAll("{email}", variable['email'])
                text = text.replaceAll("{phone}", variable['phone'])
                text = text.replaceAll("{paket}", variable['package_name'])
                text = text.replaceAll("{balance}", this.formatRupiah(variable['balance']))
                text = text.replaceAll("{expired}", moment.utc(variable['expired_at']).local().format("YYYY-MM-DD"))

                return this.dynamicString(text)
            },
            dynamicString(message) {
                let match;
                while ((match = message.match(/\(([^()]*)\)/)) !== null) {
                    let split = match[1].split("|");
                    if (split.length <= 1) {
                    return message; // Hindari pengulangan tak terbatas jika tidak ada pilihan yang valid.
                    }
                    const randomNumber = (min, max) => {
                    min = Math.ceil(min);
                    max = Math.floor(max);
                    return Math.floor(Math.random() * (max - min + 1)) + min;
                    };

                    let random = randomNumber(0, split.length - 1);
                    let replacer = split[random];
                    message = message.replace(match[0], replacer);
                }

                return message;
            },
            async sendSingle() {
                this.progress = 0
                this.toggleModal("send_progress")

                try {
                    const formData = new FormData();

                    let url = ""

                    if (this.form.date == "") {
                        this.form.date = moment().utc().valueOf()
                    } else {
                        this.form.date = moment(this.form.date).utc().valueOf()
                    }

                    if (this.form.file) {
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        url = response.data.data.url
                    }
                    this.progress = 30

                    let message = {
                        //device_id: this.form.sender,
                        schedule: this.form.date,
                        broadcast: []
                    }

                    let delay_int = 0

                    let selected = this.multiselect.value
                    let progress_counter = 50 / selected.length

                    // console.log(url, message, delay_int, progress_counter)
                    let res = await this.$store.dispatch("admin_user_message_list", this.form);
                    res = res.data.data.users

                    this.progress = this.progress + progress_counter

                    for (let i = 0; i < res.length; i++) {
                        let message_to_push = {
                            message_type: this.form.message_type,
                            to: res[i].phone,
                            text: await this.renderMessage(res[i], this.form.text),
                            file: url,
                            delay: delay_int,
                            footer: this.form.footer
                        }

                        if (this.form.message_type == "button") {
                            message_to_push.button = []

                            for (let i = 0; i < this.button.length; i++) {
                                let button_to_push = {
                                    button_id: this.button[i].id.split("-")[1],
                                    button_type: this.button[i].type,
                                    display_text: this.button[i].displayText,
                                }

                                if (this.button[i].type == "urlButton") {
                                    button_to_push.button_action = this.button[i].url
                                } else if (this.button[i].type == "callButton") {
                                    button_to_push.button_action = this.button[i].phoneNumber
                                }

                                message_to_push.button.push(button_to_push)
                            }
                        }

                        message.broadcast.push(message_to_push)

                        delay_int += Math.ceil(Math.random() * (parseInt(this.form.delay_end) - parseInt(this
                            .form.delay_start)) + parseInt(this.form.delay_start))
                    }

                    let finalMessage = []
                    let broadcastMessage = message.broadcast
                    let count = message.broadcast.length

                    if (count > 1) {
                        let sizePerDevice = Math.ceil(count / this.form.sender.length)
                        let chunkArray = this.chunkArrayInGroups(broadcastMessage, sizePerDevice)

                        for (let i = 0; i < this.form.sender.length; i++) {
                            finalMessage.push({
                                device_id: this.form?.sender[i]?.id,
                                schedule: this.form.date,
                                broadcast: chunkArray[i]
                            })
                        }
                    } else {
                        finalMessage.push({
                            device_id: this.form?.sender[0].id,
                            schedule: this.form.date,
                            broadcast: message.broadcast
                        })
                    }

                    let sendMessage
                    for (let i = 0; i < finalMessage.length; i++) {
                        sendMessage = await this.$store.dispatch('outgoing_send', finalMessage[i])
                    }

                    this.progress = 100
                    this.resetInput()
                    this.$toast.success(sendMessage.data.message)
                    this.toggleModal("send_progress")
                    this.progress = 0
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            chunkArrayInGroups(arr, size) {
                var myArray = [];
                for (let i = 0; i < arr.length; i += size) {
                    myArray.push(arr.slice(i, i + size));
                }

                let lastDelay = []
                for (let i = 0; i < myArray.length; i++) {
                    lastDelay.push(myArray[i][myArray[i].length - 1].delay)
                }

                for (let i = 0; i < myArray.length; i++) {
                    if (i != myArray.length - 1) {
                        for (let j = 0; j < myArray[i + 1].length; j++) {
                            myArray[i + 1][j].delay -= lastDelay[i]
                        }
                    }
                }

                return myArray;
            },
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
        },
    };
</script>

<style scoped>

</style>

<style scoped>
    .thick-border {
        border: 2px solid #6c6c6c !important;
    }
</style>