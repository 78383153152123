<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button class="btn btn-success text-white margin5" v-on:click="loggedInUser(props.row.id)">
            Login
          </button>
          <br>
          <router-link :to="'/admin/users/edit?id=' + props.row.id">
            <a class="btn btn-primary text-white margin5">Edit</a>
          </router-link>
          <br>
          <a v-on:click="deleteUser(props.row.id)" class="btn btn-danger text-white margin5">
            Hapus
          </a>
        </span>
        <span v-else-if="props.column.field == 'user_package.package'">
          {{ loadPackage(props.row.user_package.package_id) }}
        </span>
        <span v-else-if="props.column.field == 'user_package.expired_at'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field == 'balance'">
          {{ formatRupiah(props.row.balance) }}
          <br>
          Withdrawable : {{ formatRupiah(props.row.withdrawable_balance) }}
        </span>
        <span v-else-if="props.column.field == 'created_by'">
          {{ u[props.row.owner] }}
        </span>
        <span v-else-if="props.column.field == 'level'">
          <span class="badge badge-success" v-if="props.row.level == 'admin'">Admin</span>
          <span class="badge badge-warning text-white" v-else-if="props.row.level == 'agency'">Agency</span>
          <span class="badge badge-secondary text-white" v-else-if="props.row.level == 'user-agency'">User Agency</span>
          <span class="badge badge-info text-white" v-else>User</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [{
              label: "Username",
              field: "username",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "15%",
            },
            {
              label: "Email",
              field: "email",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "15%",
            },
            {
              label: "Phone",
              field: "phone",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Balance",
              field: "balance",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Paket",
              field: "user_package.package",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Kedaluwarsa",
              field: "user_package.expired_at",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Created By",
              field: "created_by",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Level",
              field: "level",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            }, ],
            page: 1,
            limit: 10,
          },
        },
        p: [],
        u: [],
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteCoupon(id) {
        try {
          let response = await this.$store.dispatch("admin_coupon_delete", id);
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          }, ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let p = await this.$store.dispatch('package_list')
          this.p = p.data.data.packages
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.users ?? [];

          for (let i = 0; i < this.table.rows.length; i++) {
            const element = this.table.rows[i];
            if (!this.u[element.owner]) {
              await this.loadOwner(element.owner)
            }
          }

        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        return await this.$store.dispatch("admin_user_list", param);
      },
      loadPackage(package_id) {
        for (let i = 0; i < this.p.length; i++) {
          const element = this.p[i];
          if (element.id == package_id) {
            return element.name
          }
        }
      },
      async loadOwner(owner) {
        if (!owner || owner == "0") {
          this.u[owner] = "-"
        } else {
          let u = await this.$store.dispatch('admin_user_one', {
            id: owner
          })
          this.$set(this.u, owner, u.data.data.user.username ?? "-")
        }
      },
      async loggedInUser(id) {
        try {
          await this.$store.dispatch("auth_login_admin", {id: id});
          await this.$root.$emit("login", true);
          // await this.$toast.success("Login success");
          await this.$router.push("/dashboard");
          window.location.reload()
        } catch (err) {
          this.$toast.error(err.response.data.message);
        }
      },
      formatDate(val) {
        return moment.unix(val / 1000).format('YYYY-MM-DD')
      },
      formatRupiah(numb) {
        const format = numb.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
        return rupiah
      },
      async deleteUser(id){
        if (!confirm("Yakin ingin hapus user ?")) return

        try {
          let res = await this.$store.dispatch("admin_user_delete", {
            id: id
          })
          this.$toast.success(res.data.message)
          this.loadItems();
        } catch (err) {
          this.$toast.error(err.response.data.message)
        }
      }
    },
  };
</script>

<style scoped>
  .margin5 {
    margin: 5px;
  }
</style>