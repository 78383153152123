<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Laporan</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="!isLoading" class="col-lg-12">
                    <div class="row">
                        <div class="col-xxl-3 col-sm-3 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-total">{{ formatNumber(analytics.users_count) }}</h1>
                                            <p>Total User</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-sm-3 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-aktif">{{ formatNumber(analytics.users_subsribers_count) }}</h1>
                                            <p>User Berlangganan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-sm-3 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.users_trial_count) }}</h1>
                                            <p>User Trial</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-sm-3 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.users_inactive_count) }}</h1>
                                            <p>User Tidak Aktif</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-sm-6 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.users_agency.total_records) }}
                                                ({{ formatRupiah(analytics.users_agency.total_price) }})</h1>
                                            <p>Member Paid Agency</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-sm-6 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.users_non_agency.total_records) }}
                                                ({{ formatRupiah(analytics.users_non_agency.total_price) }})</h1>
                                            <p>Member Paid non-Agency</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.devices_count) }}</h1>
                                            <p>Device Aktif</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.outgoings_count) }}</h1>
                                            <p>Pesan Keluar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="user-trial">{{ formatNumber(analytics.incomings_count) }}</h1>
                                            <p>Pesan Masuk</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="saldo">{{ formatRupiah(analytics.withdraw_total) }}</h1>
                                            <p>Total Withdraw</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="saldo">{{ formatRupiah(analytics.user_total_withdrawable_balance) }}</h1>
                                            <p>Total Withdrawable</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-sm-4 col-ssm-12 mb-30">
                            <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                                <div
                                    class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                    <div>
                                        <div class="overview-content">
                                            <h1 id="saldo">{{ formatRupiah(analytics.user_total_balance) }}</h1>
                                            <p>Total Saldo User</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <a href="https://analytics.google.com/analytics/web/#/p276082162/reports/reportinghub"
                            class="btn btn-warning btn-block text-white" target="_blank"> Google Analytics </a>
                    </div>
                </div>
                <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                    <center>
                        <div class="spinner-border spinner" role="status"></div>
                    </center>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                isLoading: true,
                analytics: {}
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            async loadData() {
                try {
                    let analytics = await this.$store.dispatch("admin_analytics")
                    this.analytics = analytics.data.data
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            formatNumber(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                return convert.join('.').split('').reverse().join('')
            },
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
        }
    }
</script>