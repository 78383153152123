<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List All Device
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <GroupTable />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GroupTable from "@/components/tables/AllDevice.vue";

    export default {
        components: {
            GroupTable,
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: [],
                deviceQuota: 0
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.deviceQuota = this.$store.state.auth.user.max_device
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            }
        },
    };
</script>