<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Withdraw
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <WithdrawTable/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WithdrawTable from "@/components/tables/Withdraw.vue";
    import moment from 'moment'

    export default {
        components: {
            WithdrawTable,
        },
        data() {
            return {
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            async exportContact() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('outgoing_export')
                        .then((response) => {
                            this.exportJson = response.data.data.outgoings

                            for (let i = 0; i < this.exportJson.length; i++) {
                                this.exportJson[i].jadwal = moment.utc(this.exportJson[i].jadwal).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].delivery_schedule = moment.utc(this.exportJson[i].delivery_schedule).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].created_at = moment.utc(this.exportJson[i].created_at).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].updated_at = moment.utc(this.exportJson[i].updated_at).local().format("YYYY-MM-DD HH:mm:ss")
                            }
                        })
                        .then(() => {
                            this.$refs.exportOutgoing.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
        },
    };
</script>
