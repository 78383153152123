<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <router-link :to="'/admin/coupon/edit?id=' + props.row.id">
            <a class="btn btn-primary text-white margin5">Edit</a>
          </router-link>
          <a v-on:click="deleteCoupon(props.row.id)" class="btn btn-danger text-white margin5">
            Hapus
          </a>
        </span>
        <span v-else-if="props.column.field == 'expired_at'">
          {{ formatDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.field == 'discount'">
          <div v-if="props.row.mode == 1">
            {{ formatRupiah(props.formattedRow[props.column.field]) }}
          </div>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}%
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [{
              label: "Nama",
              field: "name",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Kode Kupon",
              field: "code",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "15%",
            },
            {
              label: "Discount",
              field: "discount",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Kuota Sisa",
              field: "quota_left",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Kuota Max",
              field: "max_quota",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Expired At",
              field: "expired_at",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "15%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            }, ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteCoupon(id) {
        try {
          let response = await this.$store.dispatch("admin_coupon_delete", id);
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          }, ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.coupons ?? [];
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        return await this.$store.dispatch("admin_coupons_list", param);
      },
      formatDate(val) {
        return moment.unix(val / 1000).format('YYYY-MM-DD')
      },
      formatRupiah(numb) {
        const format = numb.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
        return rupiah
      },
    },
  };
</script>

<style scoped>
  .margin5 {
    margin: 5px;
  }
</style>