<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    Daftar Pengguna
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('modal_user')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Pengguna
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <AdminTable @edit="handleEdit" />
                    </div>
                </div>
                <modal name="modal_user" :height="'auto'" :adaptive="true" :scrollable="true">
                    <div style="margin: 20px">
                        <FormUser :defaultValue="form" />
                    </div>
                </modal>
            </div>
        </div>
    </div>
</template>

<script>
    import AdminTable from "@/components/tables/Admin.vue";
    import FormUser from "@/components/forms/admin/FormUser.vue";

    import moment from 'moment'

    export default {
        components: {
            AdminTable,
            FormUser,
        },
        data() {
            return {
                exportJson: [],
                form: {
                    name: "",
                    number: "",
                    email: "",
                    password: "",
                    max_device: 0,
                    max_cs: 0,
                    max_link_rotator: 0,
                    expired_at: "",
                    role: "user",
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            async exportUsers() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('user_export')
                        .then((response) => {
                            let rawData = response.data.data.users
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                if (rawData[i].phone.startsWith('0')) {
                                    rawData[i].phone = '62' + rawData[i].phone.substr(1)
                                }

                                final.push({
                                    "ID": rawData[i].id,
                                    "Name": rawData[i].name,
                                    "Email": rawData[i].email,
                                    "Username": rawData[i].username,
                                    "Phone": rawData[i].phone,
                                    "Balance": rawData[i].balance,
                                    "Withdrawable Balance": rawData[i].withdrawable_balance,
                                    "Package":  rawData[i].package_name,
                                    "Expired At":  moment.utc(rawData[i].expired_at).local().format("YYYY-MM-DD"),
                                    "Role":  rawData[i].level,
                                    "Status":  rawData[i].status,
                                    "Owner":  rawData[i].owner,
                                })
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportNow.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            handleEdit(payload) {
                this.toggleModal('modal_user');
                this.form = {
                    id: payload.id,
                    name: payload.name,
                    number: payload.number || payload.phone,
                    email: payload.email,
                    password: "",
                    max_device: payload.max_device,
                    max_cs: payload.max_cs,
                    max_link_rotator: payload.max_link_rotator,
                    expired_at: moment(payload.expired_at).format("YYYY-MM-DD"),
                    role: payload.level,
                    status: payload.status,
                }
            }
        },
    };
</script>
