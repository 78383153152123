<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Pengaturan</h4>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading" class="row">
                <div class="col-md-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30 ">
                        <form v-on:submit.prevent="updateSetting">
                            <label for="">Pengirim Primary</label>
                            <select v-model="setting.sender_device_id" class="form-control" required>
                                <option value="" selected disabled>== PILIH DEVICE ==</option>
                                <option v-for="d in device" :key="d.device_id" :value="d.device_id">{{ d.name }}</option>
                            </select>
                            <br>
                            <label for="">Pengirim Secondary (backup)</label>
                            <select v-model="setting.sender_device_id_backup" class="form-control" required>
                                <option value="" selected disabled>== PILIH DEVICE ==</option>
                                <option v-for="d in device" :key="d.device_id" :value="d.device_id">{{ d.name }}</option>
                            </select>
                            <br>
                            <button type="submit" class="btn btn-success btn-block"> Simpan </button>
                        </form>
                    </div>
                </div>
            </div>
            <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                <center>
                    <div class="spinner-border spinner" role="status"></div>
                </center>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                isLoading: true,
                analytics: {},
                device: [],
                packages: [],
                setting: {
                    sender_device_id: "",
                    sender_device_id_backup: ""
                },
                popup: {
                    new_file: "",
                    expired_at: moment().format("YYYY-MM-DD"),
                },
                announcement: {
                    expired_at: moment().format("YYYY-MM-DD"),
                }
            }
        },
        mounted() {
            this.getDevice({
                status: "all"
            })
            // this.getPackage()

            this.loadData()
        },
        methods: {
            async loadData() {
                try {
                    let setting = await this.$store.dispatch("admin_setting")
                    this.setting = setting.data.data.setting
                    // this.announcement = setting.data.data.announcement
                    // this.popup = setting.data.data.popup

                    // this.announcement.expired_at = this.announcement.expired_at ? moment.utc(this.announcement
                    //     .expired_at).local().format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
                    // this.popup.expired_at = this.popup.expired_at ? moment.utc(this.popup.expired_at).local()
                    //     .format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async getPackage() {
                try {
                    let res = await this.$store.dispatch('package_list')
                    this.packages = res.data.data.packages
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async updateSetting() {
                try {
                    this.setting.komisi_referral = parseFloat(this.setting.komisi_referral)
                    this.setting.komisi_agency = parseFloat(this.setting.komisi_agency)
                    this.setting.withdraw_fee = parseFloat(this.setting.withdraw_fee)
                    let response = await this.$store.dispatch('admin_setting_update', this.setting)
                    this.$toast.success(response.data.message);

                    this.isLoading = true
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async updateAnnouncement() {
                try {
                    let response = await this.$store.dispatch('admin_announcement_update', {
                        text: this.announcement.text,
                        expired_at: parseInt(moment(this.announcement.expired_at).utc().valueOf())
                    })
                    this.$toast.success(response.data.message);

                    this.isLoading = true
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async updatePopup() {
                try {
                    let url = this.popup.file ?? ""
                    if (this.popup.new_file) {
                        const formData = new FormData();

                        formData.append('file', this.popup.new_file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        url = response.data.data.url
                    }

                    let response = await this.$store.dispatch('admin_popup_update', {
                        title: this.popup.title,
                        text: this.popup.text,
                        file: url,
                        expired_at: parseInt(moment(this.popup.expired_at).utc().valueOf())
                    })
                    this.$toast.success(response.data.message);

                    this.isLoading = true
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.popup.new_file = selectedFile;
            },
            formatNumber(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                return convert.join('.').split('').reverse().join('')
            },
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
        }
    }
</script>