<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Kirim Pesan User</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert-info" role="alert">
                <div class="alert-content">
                    <p>Tanggal & jam pengiriman mengikuti waktu di menu profile | {{ now }} <b>UTC {{ timezone }}</b></p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <div class="tab-wrapper">
                            <div class="atbd-tab tab-horizontal">
                                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.one }" v-on:click="changePage('one')">Kirim Pesan</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.two }" v-on:click="changePage('two')">Kirim Whatsapp</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.three }" v-on:click="changePage('three')">Kirim Email</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <!-- <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                        <SendSingle :device="device"></SendSingle>
                                    </div> -->
                                    <div class="tab-pane fade" :class="{ 'show active': page.two }" id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <SendWhatsapp :device="device"></SendWhatsapp>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.three }" id="tab-v-3" role="tabpanel" aria-labelledby="tab-v-3-tab">
                                        <!-- <SendEmail :device="device"></SendEmail> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SendWhatsapp from "@/components/forms/admin/SendWhatsapp.vue";
    // import SendEmail from "@/components/forms/admin/SendEmail.vue";
    import moment from 'moment'

    export default {
        components: {
            SendWhatsapp,
            // SendEmail
        },
        data() {
            return {
                messageQuota: 0,
                form: {
                    sender: "",
                    receiver: "",
                    text: "",
                    file: "",
                    date: "",
                    time: "",
                    send_now: false,
                },
                page: {
                    one: false,
                    two: true,
                    three: false
                },
                device: [],
                timezone: "",
                now: "00:00:00",
                interval: null,
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
            clearInterval(this.interval)
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.getDevice({})
            this.getTimeZone()

            this.messageQuota = this.$store.state.auth.package.message_quota

            this.interval = setInterval(() => {
                this.now = moment().format("YYYY-MM-DD HH:mm:ss")
            }, 1000)
        },
        methods: {
            getTimeZone() {
                var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
                this.timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            changePage(page){
                this.page.one = false
                this.page.two = false
                this.page.three = false

                if(page == "one") this.page.one = true
                if(page == "two") this.page.two = true
                if(page == "three") this.page.three = true
            }
        },
    };
</script>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }
</style>